import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { login_url, lang } from '../lang';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import api from '../api';

export default function Activate(props) {
    const {styles} = props;
    const search = useLocation().search;
    const search_params = new URLSearchParams(search);
    let email = (search_params.get('email'))?search_params.get('email'):null;
    let [error, setError] = useState('');
    let [showError, setShowError] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let [info, setInfo] = useState('');
    let [timer, setTimer] = useState(5);
    let [showInfo, setShowInfo] = useState(false);
    let redirectpage = login_url;

    const fetchInit = async (key) =>{
        try {
            const ip_response = await axios.get('https://geolocation-db.com/json/');
            // setIP(res.data.IPv4);
            const response = await api.get(`activate?email=${email}&check=${key}`)
            if(response.data.status==="ok"){
                const result = response.data.result;
                localStorage.setItem('email',email);
                localStorage.setItem('ip',ip_response.data.IPv4);
                localStorage.setItem('cmu_account',JSON.stringify(result));
                localStorage.setItem('token',key);
                localStorage.setItem('login_time',new Date().toISOString());
                // update user session
                let data = {
                    email: email,
                    ip: ip_response.data.IPv4,
                    token: key,
                    user: email,
                    cmu_account: JSON.stringify(result),
                }
                const admin_session = await api.post(`rsvn_user/session`,data);
                if(admin_session.data.status==="ok"){
                    setShowInfo(true);
                    setInfo('Login Success');
                }else{
                    setShowError(true);
                    setError(admin_session.data.message);
                }
            }else{
                setShowError(true);
                let message = JSON.stringify(response.data.Message);
                setError(message);
            }

        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(() => {
        const get_code =searchParams.get("key");
        // request access token 
        if(get_code===null){
            window.location = process.env.PUBLIC_URL + '/rsvn?menu=login';
        }else{
            fetchInit(get_code);
        }
    },[]);

    useEffect(() => {
        if(!showError){
            let cerror = searchParams.get("error");
            let error_description = searchParams.get("error_description");
            if(cerror===null&&error_description==null){
                if (timer > 0) {
                    setTimeout(() => setTimer(timer - 1), 1000);
                } else {
                    clearTimeout();
                    setTimer('0');
                    window.location = process.env.PUBLIC_URL +'/rsvn';
                }
            }else{
                setShowError(true);
                setError(error_description);
            }
        }
    });

    return (
        <div className="login">
            <h3>{lang("เปิดใช้งานอีเมล์ กรุณารอสักครู่","Activate progress..")}</h3>
            {(showError)?(
            <>
                <div className="alert alert-danger">
                    <p>
                        <b>Error:</b> {error}
                    </p>
                </div>
                <fieldset>
                    <p className="register">
                        <i className="fa-solid fa-key"></i> 
                        Please&nbsp;
                        <a href={process.env.PUBLIC_URL + "/authen"} className="a-links">re-login</a> 
                        &nbsp;for authen you account&nbsp;
                    </p>
                </fieldset>
            </>
            ):(
                <>
                    {(showInfo)?(
                        <div className="alert alert-info">
                            <p className="register">
                                <b>{info}:</b><br /> You should be automatically redirected in <span className="text-red-400">{timer}</span> seconds.
                            </p>
                        </div>
                    ):(
                        <div className="alert alert-info">
                            <p className="register">
                                <b>Successful login:</b><br /> You should be automatically redirected in <span className="text-red-400">{timer}</span> seconds.
                            </p>
                        </div>
                    )}
                    
                </>
            )}
        </div>
    )
}