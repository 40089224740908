import { useState, useEffect, lazy, Suspense } from "react"
import { useParams } from 'react-router'
import api from '../components/api'
// import Ebook from '../components/Homepage/Ebook'
// import Resource from '../components/Homepage/Resource'
// import Slide from '../components/Homepage/Slide'
// import '../assets/css/article.css';
import styles from '../assets/css/article.module.css'

const ArticleLists = lazy(() => import ('../components/Article/ArticleLists'))
const ArticleDetail = lazy(() => import ('../components/Article/ArticleDetail'))

export default function Article(props) {
    const [article_id, setArticleID] = useState(0);
    const params = useParams();

    useEffect(()=>{
        if(params.articleId){
            setArticleID(params.articleId);
        }else{
          if(props.id){
            setArticleID(props.id);
          }else{
            setArticleID(0);
          }
        }
        window.addEventListener("scroll", reveal);
        reveal();
    },[]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }

    return (
        <div className={styles["article-container"]}>
              <div className={"content reveal"}>
                  {(article_id===0)?(
                    <Suspense fallback={<h1>Loading News & Activities</h1>}>
                        <ArticleLists styles={styles} setArticleID={setArticleID} />
                    </Suspense>
                  ):(
                    <Suspense fallback={<h1>Loading Article</h1>}>
                        <ArticleDetail styles={styles} article_id={article_id} />
                    </Suspense>
                  )}
              </div>
        </div>
    )
}