import { useState, useEffect } from 'react'
import api from '../api'
import { lang, addLeadingZeros } from '../lang'
import classes from '../../assets/css/form.module.css';

export default function Profile() {
	const today = new Date().toISOString().split('T')[0];
    const mail = localStorage.getItem("email");
    const [email, setEmail] = useState(mail);
    const [password, setPassword] = useState('');
    const [old_password, setOldPassword] = useState('');
    const [name, setName] = useState('');
    const [faculty, setFaculty] = useState('');
    const [phone, setPhone] = useState('');
    const [user_info, setUserInfo] = useState('');
    const [active, setActive] = useState(1);
    const [create_ip, setCreateIP] = useState('');
    const [id, setId] = useState(0);
    const [error, setError] = useState(0);
    const [success, setSuccess] = useState(0);
    const [msg, setMsg] = useState('');
    
    const fetchInit = async () =>{
        try {
            const response = await api.get('/rsvn_user?email='+mail);
            let result = response.data.results;
            setEmail(result.email);
            setId(result.id);
            setOldPassword(result.password);
            setPassword('');
            setName(result.name);
            setEmail(result.email);
            setFaculty(result.faculty);
            setPhone(result.phone);
            setUserInfo(result.user_info);
            setActive(result.active);
            setCreateIP(result.create_ip);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    useEffect(()=>{
        if(!mail){
            window.location = process.env.PUBLIC_URL + '/rsvn?menu=login';
        }
        fetchInit();
    },[]);

    function handleSubmit(event){
        event.preventDefault();
        setError(0);
        setSuccess(0);
        setMsg('');

        if(!email){
            let message = lang('กรุณาตรวจสอบอีเมล์..','Please Check your email..');
            setError(1);
            setMsg(message);
            return
        }else if(!id){
            let message = lang('กรุณาเข้าสู่ระบบก่อนแก้ไขข้อมูล..','Please sign in before update Profile..');
            setError(1);
            setMsg(message);
            return
        }else if(!name){
            let message = lang('กรุณากรอกชื่อ-นามสกุล..','Please enter your name..');
            setError(1);
            setMsg(message);
            return
        }else{
            // update user profile
            const user=localStorage.getItem('email');
            const ip=localStorage.getItem('ip');
            const jsonData = {
                id: id,
                email: email,
                password: password,
                name: name,
                phone: phone,
                faculty: faculty,
                user_info: user_info,
                create_ip: create_ip,
                active: active,
                user: user,
                ip: ip,
            } 
            api.post(`rsvn_user/update`,jsonData)
            .then(response => {
                if(response.data.status==='ok'){
                    setSuccess(1);
                    setPassword('');
                }else{
                    setError(1);
                    let message = JSON.stringify(response.data.message);
                    setMsg(message);
                }
            })
            .catch(error => {
                setError(1);
                setMsg(error);
            });
        }
    }
    return (
        <div>
            <h3>
                {lang("จองห้อง","Booking")}
            </h3>
            <div className={classes["booking-form"]}>
                {(error===1)?(
                    <div className="notice danger">
                        <div><i className="fa-solid fa-triangle-exclamation"></i></div>
                        <div className="info">
                            <b>{lang('ไม่สามารถบันทึกข้อมูลได้','Can\'t booking..')}</b><br />
                            {msg}
                        </div>
                    </div>
                    ):''}
                {(success===1)?(
                <div className="notice active">
                    <div><i className="fa-regular fa-file-lines"></i></div>
                    <div className="info">
                        <b>{lang('บันทึกข้อมูลเรียบร้อย!','Successfuly!')}</b>
                        <br />{lang('ทำรายการบันทึกข้อมูลเรียบร้อย..','Complete booking!')}
                    </div>
                    <div>
                        <i className="fa-solid fa-xmark btn-close" onClick={(event)=>setSuccess(0)}></i>
                    </div>
                </div>
                ):''}
                <form className={classes["main-form"]}>
                    <fieldset>
                        <label htmlFor="email">{lang('อีเมล์',"Email")}</label>
                        <input type="text" name="email" value={email} disabled />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="name">{lang('ชื่อ-นามสกุล','Name')} <span className={classes["etc"]}>*</span></label>
                        <input type="text" name="name" id="name" value={(name)?name:''} onChange={e=>setName(e.target.value)} />
                    </fieldset>
                    {(old_password!=='')?
                    <fieldset>
                        <label htmlFor="password">{lang('รหัสผ่าน',"Password")}</label>
                    <div className="input-group">
                        <input type="password" name="password" value={password} onChange={e=>setPassword(e.target.value)} />
                    <span className={classes["etc"]}>* {lang('หากไม่ต้องการเปลี่ยนรหัสผ่าน กรุณาเว้นช่องว่าง',"Please don't fill this input if you don't want to change your password")}</span>
                    </div>
                    </fieldset>
                    :''}
                    <fieldset>
                        <label htmlFor="faculty">{lang('หน่วยงานที่สังกัด',"Faculty")}</label>
                        <input type="text" name="faculty" value={faculty} onChange={e=>setFaculty(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="phone">{lang('หมายเลขโทรศัพท์',"Phone")}</label>
                        <input type="text" name="phone" value={phone} onChange={e=>setPhone(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="button"></label>
                        <button onClick={e=>handleSubmit(e)} className={classes["btn"]}>{lang('ตกลง','Update')}</button>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}
