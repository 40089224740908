import React, { useState, useEffect } from 'react'
import api from '../api'
import { lang, gotoURL } from '../lang'
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid' 
import interactionPlugin from "@fullcalendar/interaction";
import thLocale from '@fullcalendar/core/locales/th';
import Alert from "sweetalert2";

export default function Calendar(props) {
    const {setCalendarMode, events, eventClick, renderEventContent, showButton, dateClick} = props;
    
    const [language, setLanguage] = useState(thLocale);

    useEffect(()=>{
        let lang = localStorage.getItem("lang");
        if(lang==="en"){
            setLanguage(null);
        }else{
            setLanguage(thLocale);
        }
    },[]);

  return (
    <FullCalendar
        customButtons={{
            myCustomButton: {
                text: lang('รายวัน','Daily'),
                click: function() {
                    setCalendarMode("daily");
                },
            },
            myCustomButton2: {
                text: lang('จองห้อง','Booking'),
                click: function() {
                    gotoURL("/rsvn?menu=create");
                },
            },
        }} 
        headerToolbar={{
            left: 'today',
            center: 'prev title next',
            right: `${(showButton)?'myCustomButton2 ':''}myCustomButton`
        }} 
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        events={events}
        editable={true}
        eventClick={eventClick}
        eventContent={renderEventContent} 
        locale={language}
        eventTimeFormat={{hour: '2-digit',minute: '2-digit', hour12: false ,meridiem: false}}
        dateClick={dateClick}
        dayMaxEventRows={1}
        moreLinkContent={function(args){
            return lang('จองแล้ว '+args.num+'',args.num+' Bookings');
        }}
        navLinks={false}
        // firstDay={0}
    />
  )
}
