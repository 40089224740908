import { useState, useEffect } from 'react'
import { lang } from '../lang'
import api from '../api'
import styles from '../../assets/css/menu.module.css';
import cstyles from '../../assets/css/calendar.module.css';

export default function Menu(props) {
    const { setMenu, menu, right, className} = props;
    const email = localStorage.getItem("email");
    const [menus, setMenus] = useState([]);
    const [itaccounttype_id, setITAccountTypeID] = useState('StdAcc');

    useEffect(()=>{
        let account = JSON.parse(localStorage.getItem("cmu_account"));
        if(account&&account.itaccounttype_id!==null){
            setITAccountTypeID(account.itaccounttype_id);
        }
        fetchInit();
    },[]);

    const fetchInit = async () =>{
        try {
            const response = await api.get('/menu?type=rsvn-menu');
            setMenus(response.data.results);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }


    const handleClick = (new_menu) =>{
        if(new_menu==="logout"){
            localStorage.removeItem("email");
            localStorage.removeItem("cmu_account");
            localStorage.removeItem("token");
            localStorage.removeItem("login_time");
            // new_menu = "calendar";
            window.location = process.env.PUBLIC_URL + "/rsvn"
        }
        setMenu(new_menu);
    }

    return (
        <div className={className+` ${(right===true)?cstyles["menu"]+' '+styles["right"]:cstyles["menu"]}`}>
            <div className={styles["booking-btn"]+' '+styles["menu-section"]+' '+cstyles["booking-btn"]}>
                {(!email)?(
                    <button onClick={e=>handleClick("login")} className={(menu==="login")?styles["active"]:''}><i className="fa-solid fa-calendar-days"></i>{lang('จองห้อง','Booking')}</button>
                ):(
                    <button onClick={e=>handleClick("create")} className={(menu==="create")?styles["active"]:''}><i className="fa-solid fa-calendar-days"></i>{lang('จองห้อง','Booking')}</button>
                )}
            </div>
            <div className={styles["category"]+' '+styles["menu-section"]}>
                <h4>{lang('หมวดหมู่','Categories')}</h4>
                <ul>
                    {(email&&itaccounttype_id!=='StdAcc')?(
                    <li onClick={e=>handleClick("groupcreate")} className={`${styles["period-booking"]} ${(menu==="groupcreate")?styles["active"]:''}`}>{lang('จองห้อง(เลือกช่วงเวลา)','Booking(Period)')}</li>
                    ):''}
                    <li onClick={e=>handleClick("calendar")} className={(menu==="calendar")?styles["active"]:''}>{lang('ปฏิทินการจอง','Booking Calendar')}</li>
                    <li onClick={e=>handleClick("table")} className={(menu==="table")?styles["active"]:''}>{lang('สรุปการจองรายวัน','Daily Booking')}</li>
                    {(email)?(
                    <li onClick={e=>handleClick("mybooking")} className={(menu==="mybooking")?styles["active"]:''}>{lang('การจองของฉัน','My Booking')}</li>
                    ):''}
                    {(email)?(
                    <li onClick={e=>handleClick("setting")} className={(menu==="setting")?styles["active"]:''}>{lang('ข้อมูลของฉัน','My Profile')}</li>
                    ):''}
                    {(menus&&menus.length>0)?(
                        (menus)?menus.map((item, index) => (
                            <li key={"nav_menu_"+index}>
                                <a href={process.env.PUBLIC_URL +item.link} target={item.target}>{lang(item.menu_th,item.menu_en)}</a>
                            </li>
                        )):''
                     ):''}
                    {(email)?(
                    <li onClick={e=>handleClick("logout")} className={(menu==="logout")?styles["active"]:''}>{lang('ออกจากระบบ','Logout')}</li>
                    ):''}
                </ul>
            </div>
        </div>
    )
}
