import React, { useState, useEffect } from 'react'
import { lang, thDateFormat, addLeadingZeros } from '../lang'
import api from '../api'
import DataTable from 'react-data-table-component';
import FilterComponent from './DetailFilter';
import FieldValue from "../Template/FieldValue";

export default function Table(props) {
  const {styles,all_bookings, menu} = props;
  const [bookings, setBookings] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [listsdata, setListsdata] = useState([]);
  const today = new Date().toISOString().split('T')[0];
  const [select_date, setSelectDate] = useState(today);  
  const email = localStorage.getItem("email");

  const cols = [
    {
        name: lang('ลำดับ','#'),
        selector: (row, index) =>  Number(index+1),
        center: true,
        width: '90px',
    },
    {
        name: lang('ห้อง','Rooms'),
        selector: row => <FieldValue value={row.room_name} keyword={filterText}/>,
        center: true,
        sortable: false,
    },
    {
        name: lang('วันที่','Date'),
        selector: row => <FieldValue value={thDateFormat(row.booking_date)} keyword={filterText}/>,
        center: true,
        sortable: false,
    },
    {
        name: lang('เวลา','Time'),
        selector: row => <FieldValue value={row.start_time+'-'+row.end_time} keyword={filterText}/>,
        center: true,
        sortable: false,
    },
    {
        name: lang('รายละเอียด','Detail'),
        selector: row => <FieldValue value={row.title} keyword={filterText}/>,
        sortable: false,
    },
    {
        name: lang('ผู้จอง','Booking By'),
        selector: row => <FieldValue value={row.booking_by} keyword={filterText}/>,
        sortable: false,
    },
];

useEffect(()=>{
  if(!email){
      window.location = process.env.PUBLIC_URL + '/rsvn?menu=login';
  }

  const filteredItems = all_bookings.filter((item) => {
      if(item.booking_email===email){
          return item;
      }
  });
  // console.log(select_date)
  setBookings(filteredItems);
  setListsdata(filteredItems);
},[all_bookings,menu]);

const onFilter = (txt) => {  
    // console.log(equipments_data)
    let filteredItems = bookings.filter(
        item => {
            return item.id.includes(txt.toLowerCase()) || 
                    (item.start_time+'-'+item.end_time).includes(txt.toLowerCase()) || 
                    item.room_name.toLowerCase().includes(txt.toLowerCase()) || 
                    item.title.toLowerCase().includes(txt.toLowerCase()) || 
                    thDateFormat(item.booking_date).includes(txt.toLowerCase()) || 
                    item.booking_by.toLowerCase().includes(txt.toLowerCase());
        }
    );
    setListsdata(filteredItems);
    setFilterText(txt);
}

/* filter and clearfilter */
const handleClear = () => {
    const txt = '';
    let filteredItems = bookings.filter(
        item => {
            return item.id.includes(txt.toLowerCase()) || 
                    (item.start_time+'-'+item.end_time).includes(txt.toLowerCase()) || 
                    item.room_name.toLowerCase().includes(txt.toLowerCase()) || 
                    item.title.toLowerCase().includes(txt.toLowerCase()) || 
                    thDateFormat(item.booking_date).includes(txt.toLowerCase()) || 
                    item.booking_by.toLowerCase().includes(txt.toLowerCase());
        }
    );
    setListsdata(filteredItems);
    setFilterText('');
};

const onSelectDate = (value) => {
  setFilterText('');
  setSelectDate(value);
}

  return (
    <div>
        <h3>{lang("การจองของฉัน","My Booking")}</h3>
        <div className={styles["full-detail"]}>
            <DataTable 
                columns={cols} 
                data={listsdata} 
                pagination 
                paginationComponentOptions={ {rowsPerPageText: lang('แสดงข้อมูลแถว:','Total Row:'), rangeSeparatorText:  lang('จาก','to'), noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: lang('แสดงทั้งหมด','All Rows') }}
                subHeader
                striped
                subHeaderComponent={<FilterComponent selectDate={select_date} setSelectDate={e => onSelectDate(e.target.value)} onFilter={e => onFilter(e.target.value)} onClear={handleClear} filterText={filterText} export={false}/>}
                paginationPerPage={10} />
            </div>
    </div>
  )
}
