import { useState, useEffect } from 'react'
import { lang } from '../lang'

export default function Preloader(props) {


    // useEffect(()=>{
    //     console.log(props.nav_data);
    // },[]);

    return (
        <div className={"preloader"}>
            <p>{props.text} Loading..</p>
        </div>
    )
}
