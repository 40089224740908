import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CourseCard from "./CourseCard";
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import { lang, gotoLink, img_url } from '../lang'
import api from '../api'

SwiperCore.use([Navigation, Pagination, A11y]);
export default function OnlineCourses(props) {
    const styles = props.styles;
    const [init, setInit] = useState([]);
	const navigate = useNavigate();
    SwiperCore.use([Autoplay]); // start autoplay
    const [article, setArticle] = useState([]);
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const init_response = await api.get('/setting');
                setInit(init_response.data.results);
                const response = await api.get('/vdos/1');
                setArticle(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    return (
        <div className={"container "+styles["activity"]}>
            <div className={styles["title"]}>
                <div className={styles["title-h1"]}>
                    <h2>Online <span>Courses</span></h2>
                </div>
            </div>
            <div className={styles["activity-container"]}>
                {/* group items  */}
                <Swiper
                    slidesPerView={5}
                    centeredSlides={false}
                    spaceBetween={50}
                    speed={900}
                    autoplay={{ delay: 3000 }}
                    grabCursor={true}
                    breakpoints={{
                      // when window width is >= 320px
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 50
                      },
                      // when window width is >= 640px
                      800: {
                        slidesPerView: 2,
                        spaceBetween: 50
                      },
                      1280 : {
                        slidesPerView: 3,
                        spaceBetween: 50
                      },
                      1536 : {
                        slidesPerView: 4,
                        spaceBetween: 50
                      }
                    }}
                    pagination={{
                        el: ".courses-swiper-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation={{
                      nextEl: ".art-courses-swiper-next",
                      prevEl: ".art-courses-swiper-prev"
                    }}
                    modules={[Pagination]}
                    effect={"fade"}
                    className="courses-swiper"
                >
                    {article.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <div className={styles["card"]+" group"}>
                                <div className={styles["image"]} onClick={() => gotoLink(item.link)}>
                                    <div className={styles["image-inner"]} style={{ backgroundImage: `url(${img_url + item.cover_img})` }}></div>
                                    <div className={styles["shine-box"]} />
                                </div>
                                <div className={styles["info"]}>
                                    <div className={styles["detail"]} dangerouslySetInnerHTML={{__html: lang(item.name_th,item.name_en)}}></div>
                                </div>
                                <span className={styles["card-end"]}></span>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <SwiperNavigation section="courses" />
            </div>
            {/* <div className={styles["readmore"]}>
                <button onClick={() => gotoLink(init.youtube)}>{lang("ดูเพิ่มเติม","View more")}</button></div> */}
        </div>
    )
}
