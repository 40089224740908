import { useEffect, useState } from 'react';
import axios from 'axios';  
import footer_styles from '../assets/css/footer.module.css';
import { lang } from './lang'
import api from './api'

function Visitors() {
  const [visitorCount, setVisitorCount] = useState([]);
  const fetchVisitor = async () => {
      try {
        let visitor = [];
      
        const response = await api.get('/visitors');
        let result = response.data;
        // visitor.push({
        //   total:result.today,
        //   title_th:"วันนี้",
        //   title_en:"Today"
        // }); 
        visitor.push({
          total:result.yesterday,
          title_th:"วันนี้",
          title_en:"Today"
        }); 
        visitor.push({
          total:result.month,
          title_th:"เดือนนี้",
          title_en:"This Month"
        }); 
        visitor.push({
          total:result.year,
          title_th:"ปีนี้",
          title_en:"This Year"
        });
        visitor.push({
          total:result.all,
          title_th:"สถิติทั้งหมด",
          title_en:"All"
        }); 
        setVisitorCount(visitor);
        localStorage.setItem("visitor",JSON.stringify(visitor));
        localStorage.setItem("last_visited",new Date().toLocaleDateString());
        
      } catch (err) {
          if(err.response){
              //Not in 202 response range
              console.log(err.response.data);
              console.log(err.response.headers);
              console.log(err.response.status);
          } else {
              console.log(`Error: ${err.message}`);
          }
      }
    }

  useEffect(() => {
    let visitor=localStorage.getItem("visitor");
    // console.log(visitor);
    if(!visitor){
        fetchVisitor();
    }else{
      var todayDate = new Date().toLocaleDateString();
      let lastVisit=localStorage.getItem("last_visited");
      if(lastVisit!==todayDate){
        fetchVisitor();
      }else{
        
        setVisitorCount(JSON.parse(visitor));
      }
    }
  }, []);

  return (
    <ul className={footer_styles["visitors"]}>
      {visitorCount !== null ? (
        visitorCount.map((item,key)=>(
          <li key={key}>
              <p>{lang(item.title_th,item.title_en)} : <span>{item.total}</span></p>
              
          </li>
        ))
      ) : (
        <p>Loading visitor count...</p>
      )}
    </ul>
  );
}
export default Visitors;