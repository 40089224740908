import {lang} from '../lang';
import classes from '../../assets/css/form.module.css';

const FilterComponent = (props) => {

    return (
    <>
		<fieldset className={"datatable-filter "+classes["mybooking-filter"]}>
			<div className={"input-group "+classes["input-group"]}>
			<input 
				type="text" 
				id="search" 
				placeholder={lang('ระบุคำค้น','Enter Keyword')}
				value={props.filterText} 
				onChange={props.onFilter} 
				aria-label="Search Input" 
				aria-describedby="datatable-search" />
				<button className={classes["btn"]}>{lang('ค้นหา','Search')}</button>
			</div>
		</fieldset>
		<small className="w-100 pb-2">{props.info}</small>
	</>
    )
};

export default FilterComponent;