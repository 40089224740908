import { useEffect,useState } from 'react'
import { lang } from './lang'
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

function CookieBanner() {
    const [show, setShow] = useState(true);
    const [show_detail, setShowDetail] = useState(false);
    const [necessary, setNecessary] = useState(true);
    const [analytics, setAnalytics] = useState(false);
    const cookie_name = "CMUL_Nurse";
    const cookies = new Cookies();

    useEffect(()=>{
        let chk=cookies.get(cookie_name);
        if(chk===undefined){
            setShow(true);
        }else{
            setShow(false);
        }
        // console.log(chk)
    },[]);

    function setCookie(){
        let categories=[]
        if(necessary===true){
            categories.push("necessary");
        }
        if(analytics===true){
            categories.push("analytics");
        }
        let detail = {categories:categories,
                        revision:0,
                        data:null,
                        rfc_cookie:false,
                        consent_date:new Date(),
                        consent_uuid:uuidv4(),
                        last_consent_update:new Date()}
        let data = JSON.stringify(detail);
        cookies.set(cookie_name, data);
        setShowDetail(false)
        setShow(false);
    }

    function setAllCookie(){
        let categories=["necessary","analytics"]
        let detail = {categories:categories,
                        revision:0,
                        data:null,
                        rfc_cookie:false,
                        consent_date:new Date(),
                        consent_uuid:uuidv4(),
                        last_consent_update:new Date()}
                        let data = JSON.stringify(detail);
        cookies.set(cookie_name, data);
        setShow(false);
    }

    return (
        (show)?
        <>
            <div className="cookie-banner">
                <p>
                    {lang('เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ และประสบการณ์ที่ดีในการใช้งานเว็บไซต์ คุณสามารถเลือกตั้งค่าความยินยอมการใช้คุกกี้ได้ โดยคลิก "การตั้งค่าคุกกี้"','We use tracking cookies to understand how you use the product and help us improve it. Please accept cookies to help us improve.')}  
                    <a href="https://www.cmu.ac.th/th/privacy" target="_blank" rel="noreferrer">({lang("นโยบายความเป็นส่วนตัว","Cookie Policy")})</a>
                </p>
                <button type="button" onClick={e=>setShowDetail(true)}>{lang('ตั้งค่าคุกกี้','Customize Setting')}</button>
                <button type="button" onClick={e=>setAllCookie(true)} className="accept">{lang('ยอมรับทั้งหมด','Accept all cookies')}</button>
            </div>
            {(show_detail===true?
            <div className="backscreen">
                <div className="modal-dialog">
                    <div className="cookie-modal">
                        <div className="close" onClick={e=>setShowDetail(false)}>X</div>
                        <h3>{lang('ประกาศความเป็นส่วนตัว','Privacy Notice')}</h3>
                        <p>{lang(`คุกกี้คือไฟล์ข้อความขนาดเล็กซึ่งถูกคัดลอกลงในฮาร์ดดิสก์ของคุณโดยเว็บไซต์ คุกกี้ไม่ทำให้คอมพิวเตอร์ของคุณเสียหายและไม่มีไวรัสใดๆ ทั้งสิ้น คุกกี้จากเว็บไซต์ของเราจะไม่เก็บรวบรวมข้อมูลส่วนตัวของคุณ คุณสามารถปิดการใช้งานคุกกี้ได้ตลอดเวลาผ่านการตั้งค่าในบราวเซอร์ของคุณ ทั้งนี้เพื่อใช้สำหรับวัตถุประสงค์ที่ไม่เป็นที่เปิดเผย การประเมินผลทางสถิติ และเพื่อการปรับปรุงความง่ายและสะดวกต่อการใช้งานสำหรับผู้ใช้ต่อไป`,`Cookies are small text files that are copied to your hard disk by a website. Cookies do not damage your computer and do not contain any viruses. Cookies from our website do not collect your personal information. You can disable cookies at any time through your browser settings. This is to be used for non-disclosure purposes. statistical evaluation and to further improve the ease and ease of use for users`)}</p>
                        <ul>
                            <li>
                                <label>
                                    <input type="checkbox" value="" className="sr-only peer" checked />
                                        <div className="toggle-div active"></div>
                                        <span>{lang('คุกกี้ที่จำเป็น','Strictly Necessary')}</span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="checkbox" value="" className="sr-only peer" checked />
                                        <div className={(analytics===true)?"toggle-div active":"toggle-div"} onClick={e=>setAnalytics(current=>!current)}></div>
                                        <span>{lang('คุกกี้เพื่อการวิเคราะห์','Analytics & Performance Cookies')}</span>
                                </label>
                            </li>
                        </ul>
                        <div className="button-comfirm">
                            <button type="button" onClick={e=>setCookie()}>{lang('ยืนยันตัวเลือกของฉัน','Confirm my choices')}</button>
                        </div>
                    </div>
                </div>
            </div>
            :'')}
        </>
        :''
    );
  }
  
  export default CookieBanner;