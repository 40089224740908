import { useState, useEffect, lazy, Suspense } from "react"
import { useParams } from 'react-router'
import api from '../components/api'
// import Ebook from '../components/Homepage/Ebook'
// import Resource from '../components/Homepage/Resource'
// import Slide from '../components/Homepage/Slide'
// import '../assets/css/article.css';
import styles from '../assets/css/ebook.module.css';

const Nav = lazy(() => import ('../components/Template/Nav'))
const BookDetail = lazy(() => import ('../components/EBook/BookDetail'))


export default function Resource() {
    const [book_id, setBookID] = useState(0);
    const [nav_data, setNavData] = useState([]);
    const [book, setBook] = useState({});
    const params = useParams();

    useEffect(()=>{
        let bookid = (params.bookId)?params.bookId:0;
        setBookID(bookid);
        let book_type = 'book';
        const fetchInit = async () =>{
          try {
            const response = await api.get('/book/'+bookid);
            book_type = response.data.results.book_type;
            setBook(current => response.data.results);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
          let menu_id = 3;
          if(book_type==='book'){
            menu_id = 11;
          }else if(book_type==='e-book'){
            menu_id = 11;
          }else if(book_type==='e-journal'){
            menu_id = 13;
          }else if(book_type==='journal'){
            menu_id = 12;
          }
          try {
            const response = await api.get('/menu/'+menu_id);
            setNavData(current => response.data);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        }
        fetchInit();
        window.addEventListener("scroll", reveal);
        reveal();
    },[]);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }

    return (
        <div className={"container "+styles["ebook-container"]}>
              <Nav styles={styles} nav_data={nav_data} />
              <div className={styles["content"]+" reveal"}>
                  <div className={styles["content-container"]}>
                    <Suspense fallback={<h1>Loading Book</h1>}>
                        <BookDetail styles={styles} data={book} setBookID={setBookID} setBook={setBook}/>
                    </Suspense>
                  </div>
              </div>
        </div>
    )
}