import React, { useState, useEffect } from 'react'
import { lang, thDateFormat, addLeadingZeros } from '../lang'
import api from '../api'
import DataTable from 'react-data-table-component';
import FilterComponent from './FilterComponent';
import FieldValue from "../Template/FieldValue";

export default function Table(props) {
  const {styles,bookings, events, dates} = props;
  const [rooms, setRooms] = useState([]);
  const [booking, setBooking] = useState({});
  const [filterText, setFilterText] = useState('');
  const [listsdata, setListsdata] = useState(bookings);
  const [success, setSuccess] = useState(0);
  const [display, setDisplay] = useState(false);
  const [rmdisplay, setRmDisplay] = useState(false);
  const today = new Date().toISOString().split('T')[0];
  const [select_date, setSelectDate] = useState(today);  

  const cols = [
    {
        name: lang('ลำดับ','#'),
        selector: (row, index) =>  Number(index+1),
        center: true,
        width: '90px',
    },
    {
        name: lang('ห้อง','Rooms'),
        selector: row => <FieldValue value={row.room_name} keyword={filterText}/>,
        center: true,
        sortable: false,
    },
    {
        name: lang('วันที่','Date'),
        selector: row => <FieldValue value={thDateFormat(row.booking_date)} keyword={filterText}/>,
        center: true,
        sortable: false,
    },
    {
        name: lang('เวลา','Time'),
        selector: row => <FieldValue value={row.start_time+'-'+row.end_time} keyword={filterText}/>,
        center: true,
        sortable: false,
    },
    {
        name: lang('รายละเอียด','Detail'),
        selector: row => <FieldValue value={row.title} keyword={filterText}/>,
        sortable: false,
    },
    {
        name: lang('ผู้จอง','Booking By'),
        selector: row => <FieldValue value={row.booking_by} keyword={filterText}/>,
        sortable: false,
    },
];

useEffect(()=>{
  const filteredItems = bookings.filter(
      item => item.booking_date.includes(select_date) ,
  );
  // console.log(select_date)
  setListsdata(filteredItems);
},[select_date]);


useEffect(()=>{
  const fetchInit = async () =>{
      try {
          const response = await api.get('/room');
          setRooms(response.data.results);
      } catch (err) {
          if(err.response){
              //Not in 202 response range
              console.log(err.response.data);
              console.log(err.response.headers);
              console.log(err.response.status);
          } else {
              console.log(`Error: ${err.message}`);
          }
      }
  }
  fetchInit();
},[]);

const onFilter = (txt) => {  
    // console.log(equipments_data)
    let filteredItems = bookings.filter(
        item => {
          if(item.booking_date.includes(select_date)){
            return item.id.includes(txt.toLowerCase()) || 
                    (item.start_time+'-'+item.end_time).includes(txt.toLowerCase()) || 
                    item.room_name.toLowerCase().includes(txt.toLowerCase()) || 
                    item.title.toLowerCase().includes(txt.toLowerCase()) || 
                    thDateFormat(item.booking_date).includes(txt.toLowerCase()) || 
                    item.booking_by.toLowerCase().includes(txt.toLowerCase());
          }
        }
    );

    setListsdata(filteredItems);
    setFilterText(txt);
}

/* filter and clearfilter */
const handleClear = () => {
    const txt = '';
    let filteredItems = bookings.filter(
        item => {
          if(item.booking_date.includes(select_date)){
            return item.id.includes(txt.toLowerCase()) || 
                    (item.start_time+'-'+item.end_time).includes(txt.toLowerCase()) || 
                    item.room_name.toLowerCase().includes(txt.toLowerCase()) || 
                    item.title.toLowerCase().includes(txt.toLowerCase()) || 
                    thDateFormat(item.booking_date).includes(txt.toLowerCase()) || 
                    item.booking_by.toLowerCase().includes(txt.toLowerCase());
          }
        }
    );
    setListsdata(filteredItems);
    setFilterText('');
};

const onSelectDate = (value) => {
  setFilterText('');
  setSelectDate(value);
}

  return (
    <div>
        <h3>{lang("สรุปการจองรายวัน","Daily Reservation")}</h3>
        <div className={styles["full-detail"]}>
            <DataTable 
                columns={cols} 
                data={listsdata} 
                pagination 
                paginationComponentOptions={ {rowsPerPageText: lang('แสดงข้อมูลแถว:','Total Row:'), rangeSeparatorText:  lang('จาก','to'), noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: lang('แสดงทั้งหมด','All Rows') }}
                subHeader
                striped
                subHeaderComponent={<FilterComponent selectDate={select_date} setSelectDate={e => onSelectDate(e.target.value)} onFilter={e => onFilter(e.target.value)} onClear={handleClear} filterText={filterText} export={false}/>}
                paginationPerPage={10} />
            </div>
    </div>
  )
}
